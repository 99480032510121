.neos-nodetypes-html-html {
	@extend %block;

	iframe {
		@extend %block;
	}

	#cookiefirst-policy-page {

		h1, h2, h3, h4, h5, h6 {
			@extend %block;
		}

		button {
			width: auto;
			height: auto;
			background: color(black);
			border: 1px solid color(white);
			color: color(white);
			text-align: center;
			padding: spacing(xsmall) spacing(small);
			opacity: 0.25;
			pointer-events: none;
			cursor: default;
			white-space: nowrap;

			&.is--available {
				opacity: 1;
				pointer-events: all;
				cursor: pointer;
			}
		}

		p {
			width: 100%;
		}
}

}	