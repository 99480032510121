/// blogal classes variables
$breakpoints: props(breakpoints);
$color--group: themeProps(colors);
$spacing--group: themeProps(spacing);
$radius--group: themeProps(radius);
$shadow--group: themeProps(shadow);

// background-color classes
@each $color, $value in $color--group {
	.bc--#{$color} {
		background: $value;
	}
}

// font-color classes
@each $color, $value in $color--group {
	.fc--#{$color} {
		color: $value;
	}
}

// margin and padding classes – inner and outer space
@each $size, $value in $spacing--group {
	// inner space
	.inner-space--#{$size} {
		padding: #{$value};
	}
	.inner-space--disable {
		padding: 0;
	}

	.inner-space--#{$size}--top-bottom {
		padding-top: #{$value};
		padding-bottom: #{$value};
	}
	.inner-space--disable--top-bottom {
		padding-top: 0;
		padding-bottom: 0;
	}

	.inner-space--#{$size}--left-right {
		padding-left: #{$value};
		padding-right: #{$value};
	}
	.inner-space--disable--left-right {
		padding-left: 0;
		padding-right: 0;
	}

	.inner-space--#{$size}--top {
		padding-top: #{$value};
	}
	.inner-space--disable--top {
		padding-top: 0;
	}

	.inner-space--#{$size}--bottom {
		padding-bottom: #{$value};
	}
	.inner-space--disable--bottom {
		padding-bottom: 0;
	}

	.inner-space--#{$size}--left {
		padding-left: #{$value};
	}
	.inner-space--disable--left {
		padding-left: 0;
	}

	.inner-space--#{$size}--right {
		padding-right: #{$value};
	}
	.inner-space--disable--right {
		padding-right: 0;
	}
	@each $breakpoint, $map in $breakpoints {
		@include for-size($breakpoint) {
			.#{$breakpoint}\:inner-space--#{$size} {
				padding: #{$value};
			}
			.#{$breakpoint}\:inner-space--disable {
				padding: 0;
			}

			.#{$breakpoint}\:inner-space--#{$size}--top-bottom {
				padding-top: #{$value};
				padding-bottom: #{$value};
			}
			.#{$breakpoint}\:inner-space--disable--top-bottom {
				padding-top: 0;
				padding-bottom: 0;
			}

			.#{$breakpoint}\:inner-space--#{$size}--left-right {
				padding-left: #{$value};
				padding-right: #{$value};
			}
			.#{$breakpoint}\:inner-space--disable--left-right {
				padding-left: 0;
				padding-right: 0;
			}

			.#{$breakpoint}\:inner-space--#{$size}--top {
				padding-top: #{$value};
			}
			.#{$breakpoint}\:inner-space--disable--top {
				padding-top: 0;
			}

			.#{$breakpoint}\:inner-space--#{$size}--bottom {
				padding-bottom: #{$value};
			}
			.#{$breakpoint}\:inner-space--disable--bottom {
				padding-bottom: 0;
			}

			.#{$breakpoint}\:inner-space--#{$size}--left {
				padding-left: #{$value};
			}
			.#{$breakpoint}\:inner-space--disable--left {
				padding-left: 0;
			}

			.#{$breakpoint}\:inner-space--#{$size}--right {
				padding-right: #{$value};
			}
			.#{$breakpoint}\:inner-space--disable--right {
				padding-right: 0;
			}
		}
	}

	// outer space
	.outer-space--#{$size} {
		margin: #{$value};
	}
	.outer-space--disable {
		margin: 0;
	}

	.outer-space--#{$size}--top-bottom {
		margin-top: #{$value};
		margin-bottom: #{$value};
	}
	.outer-space--disable--top-bottom {
		margin-top: 0;
		margin-bottom: 0;
	}

	.outer-space--#{$size}--left-right {
		margin-left: #{$value};
		margin-right: #{$value};
	}
	.outer-space--disable--left-right {
		margin-left: 0;
		margin-right: 0;
	}

	.outer-space--#{$size}--top {
		margin-top: #{$value};
	}
	.outer-space--disable--top {
		margin-top: 0;
	}

	.outer-space--#{$size}--bottom {
		margin-bottom: #{$value};
	}
	.outer-space--disable--bottom {
		margin-bottom: 0;
	}

	.outer-space--#{$size}--left {
		margin-left: #{$value};
	}
	.outer-space--disable--left {
		margin-left: 0;
	}

	.outer-space--#{$size}--right {
		margin-right: #{$value};
	}
	.outer-space--disable--right {
		margin-right: 0;
	}
	@each $breakpoint, $map in $breakpoints {
		@include for-size($breakpoint) {
			.#{$breakpoint}\:grid-gap--#{$size} {
				grid-gap: #{$value};
			}
			.#{$breakpoint}\:outer-space--#{$size} {
				margin: #{$value};
			}
			.#{$breakpoint}\:outer-space--disable {
				margin: 0;
			}

			.#{$breakpoint}\:outer-space--#{$size}--top-bottom {
				margin-top: #{$value};
				margin-bottom: #{$value};
			}
			.#{$breakpoint}\:outer-space--disable--top-bottom {
				margin-top: 0;
				margin-bottom: 0;
			}

			.#{$breakpoint}\:outer-space--#{$size}--left-right {
				margin-left: #{$value};
				margin-right: #{$value};
			}
			.#{$breakpoint}\:outer-space--disable--left-right {
				margin-left: 0;
				margin-right: 0;
			}

			.#{$breakpoint}\:outer-space--#{$size}--top {
				margin-top: #{$value};
			}
			.#{$breakpoint}\:outer-space--disable--top {
				margin-top: 0;
			}

			.#{$breakpoint}\:outer-space--#{$size}--bottom {
				margin-bottom: #{$value};
			}
			.#{$breakpoint}\:outer-space--disable--bottom {
				margin-bottom: 0;
			}

			.#{$breakpoint}\:outer-space--#{$size}--left {
				margin-left: #{$value};
			}
			.#{$breakpoint}\:outer-space--disable--left {
				margin-left: 0;
			}

			.#{$breakpoint}\:outer-space--#{$size}--right {
				margin-right: #{$value};
			}
			.#{$breakpoint}\:outer-space--disable--right {
				margin-right: 0;
			}
		}
	}
}


// radius classes
@each $radius, $value in $radius--group {
	.radius--#{$radius} {
		@include radius($value);
	}
}

// shadow classes
@each $shadow, $value in $shadow--group {
	.shadow-text--#{$shadow} {
		@include shadow(text, $value);
	}
	.shadow-box--#{$shadow} {
		@include shadow(box, $value);
	}
}

// sticky option
.sticky {
	top: 0;
	position: sticky;
	position: -webkit-sticky;
}

// text aligns
.text--left {
	text-align: left;
}
.text--right {
	text-align: right;
}
.text--center {
	text-align: center;
}
.text--justify {
	text-align: justify;
}
@each $breakpoint, $map in $breakpoints {
	@include for-size($breakpoint) {
		.#{$breakpoint}\:text--left {
			text-align: left;
		}
		.#{$breakpoint}\:text--right {
			text-align: right;
		}
		.#{$breakpoint}\:text--center {
			text-align: center;
		}
		.#{$breakpoint}\:text--justify {
			text-align: justify;
		}
	}
}