header.header {
	z-index: 9999;
	@extend %block-flex;
	justify-content: flex-end;
	position: fixed;
	top: 0;
	left: 0;
	padding-right: 5vw;
	@include transition(background 0.35s);

	&.on--scroll {
		background: color(black);
		@include transition(background 0.35s);
	}

	@include for-size(xl) {
		padding-right: 12.5vw;
	}
}	