.gdpr-cookieconsent-container {
    --cookieconsent-theme-color: #822681;
    --cookieconsent-overlay-color: rgb(0 0 0 / .5);
    --cookieconsent-bg-color: #fff;

    --cookieconsent-group-bg-color: #fff;

    --cookieconsent-button-text-color: #fff;
    --cookieconsent-button-primary-color: var(--cookieconsent-theme-color);
    --cookieconsent-button-secondary-color: rgb(255 255 255);
    --cookieconsent-button-primary-text-color: var(--cookieconsent-button-text-color);
    --cookieconsent-button-secondary-text-color: #343434;
    --cookieconsent-button-borderradius: 0;

    --cookieconsent-spacing: 1rem;
    --cookieconsent-width: 600px;

    --cookieconsent-focus-outline: transparent;
    --cookieconsent-focus-outline-offset: 0;

	flex-direction: row !important;
	justify-content: center;
    background: rgb(0 0 0 / .5);
    overflow-y: scroll;

    &::before {
        display: none;
    }

    &:focus-visible {
        outline: transparent;
    }

	.gdpr-cookieconsent-settings {
        max-width: 600px;
		position: absolute;
		top: calc(spacing(large) * 2);
		left: 50%;
        background-color: color(white) !important;
		transform: translateX(-50%);


        @media (max-width: 500px) {
            width: 90%;
        }

        @media (min-width: 501px) {
            min-width: 450px;
        }

		.hidden {
			display: none !important;
		}

		.gdpr-cookieconsent-settings__content {
			display: flex;
            flex-direction: column;
            color: color(black);
            padding: calc(spacing(medium) * 0.75);
            padding-top: spacing(large);

            @media (--media--sm) {
                padding: calc(spacing(large) * 0.75);
                padding-top: spacing(large);
            }

			.gdpr-cookieconsent-settings__content__info {
                display: block;
                order: 1;
				font-family: typo(headline-family);
				hyphens: auto;
				padding-bottom: spacing(medium);

				h2 {
                    font-size: 1.45rem;
                    color: color(black);
                    text-align: center;

                    @include for-size(sm) {
                        font-size: 1.7rem;
                    }
                    
                    @include for-size(md) {
                        font-size: 1.95rem;
                    }
                    
                    @include for-size(lg) {
                        font-size: 2.2rem;
                    }
				}

				.gdpr-cookieconsent-settings__content__info__description {
					display: block;
                    font-family: typo(base-family);
					font-size: 1.05rem;
                    line-height: 1.5;
                    color: color(black);
                    margin-bottom: spacing(small);

					p {
                        hyphens: auto;
                        text-align: center;
					}

                    &.gdpr-cookieconsent-settings__content__info__description--settings {
                        display: none;
                    }
				}
			}

            .gdpr-cookieconsent-settings__content__settings {
                order: 2;
                margin-top: 0;
                margin-bottom: spacing(small);

                .gdpr-cookieconsent-switch--group {
                    font-size: 1.05rem;

                    .gdpr-cookieconsent-switch--cookie {
                        font-size: 1.05rem;
                        color: color(black);
                    }
                }

                input:checked {
                    background: color(primary) !important;
                }

                .gdpr-cookieconsent-setting-group__details {
                    color: color(primary); 
                }
            }

			.gdpr-cookieconsent-settings__content__cta {
                display: flex;
                flex-direction: column;
                align-items: center;
                order: 3;
				margin: 0 !important;

				.gdpr-cc-button {
                    $self: &;
                    display: inline-block;
                    position: relative;
                    hyphens: auto;
                    white-space: pre-wrap !important;
                    color: color(black);
                    background: none;
                    padding: spacing(2xsmall) spacing(xsmall);
                    border: 2px solid color(black);
                    border-radius: 0;

                    &:hover {
                        color: color(white);
                        @include transition(all 0.35s);

                        &:before {
                            width: 100%;
                            height: 100%;
                            left: 0;
                            z-index: -1;
                            @include transition(all 0.35s);
                        }
                    }

                    &:before {
                        content: '';
                        width: 0;
                        height: 0;
                        position: absolute;
                        bottom: 0;
                        left: spacing(2xsmall);
                        z-index: 0;
                        background: color(black);
                        @include transition(all 0.15s 0.1s);
                    }
				}

                #gdpr-cc-btn-accept,
                #gdpr-cc-btn-save {
                    margin-bottom: spacing(medium);
                }

                .gdpr-cc-button--secondary, #gdpr-cc-btn-accept-necessary {
                    font-size: 1.05rem;
                    font-weight: 400;
                    text-transform: none;
                    color: color(black);
                    background: none !important;
                    margin: 0;
                    border: none;
                    transition: all 0.25s ease-in-out;

                    &:hover {
                        color: color(white);
                        @include transition(all 0.35s);
                    }
                }
			}

            .gdpr-cookieconsent-settings__content__links {
                order: 4;
                color: color(black);
                margin-top: spacing(medium);
				text-align: center;

                a {
                    font-weight: 400;
                    transition: all 0.25s ease-in-out;

                    @media (hover: hover) {
                        &:hover {
                            color: color(primary);
                        }
                    }
                }

                span {
                    font-weight: 500;
                }
			}
		}
	}
}

.gdpr-cookieconsent-switch {
    --cookieconsent-switch-thumb-size: 1.25rem;
    --cookieconsent-switch-thumb-color: rgb(255 255 255);
    --cookieconsent-switch-thumb-highlight-color: rgba(0 0 0 / 0.25);
    --cookieconsent-switch-length: calc(var(--cookieconsent-switch-thumb-size) * 2);
    --cookieconsent-switch-padding: 4px;
    --cookieconsent-switch-inactive-color: #E0E0E0;
    --cookieconsent-switch-active-color: #822681;
    --cookieconsent-switch-thumb-size-small: 1rem;
    --cookieconsent-switch-outline: var(--cookieconsent-focus-outline);
    --cookieconsent-switch-outline-offset: var(--cookieconsent-focus-outline-offset);
}