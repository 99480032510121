.form-select {
	$self: &;
	@extend %block-flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	position: relative;
	cursor: pointer;

	&.show {
		#{$self}__options {
			display: block;
			pointer-events: auto;
		}
	}

	&.form-select--selected {
		#{$self}__reset {
			@extend %block-flex;
			pointer-events: all;
		}

		&:hover {
			#{$self}__options {
				display: none;
			}
		}
	}

	&__reset {
		display: none;
		position: absolute;
		z-index: 10;
		pointer-events: none;
	}

	input {
		display: none;

		
	}

	

	&__current, &__reset {
		@extend %block-flex;
		justify-content: space-between;
		align-items: center;
		padding: spacing(xsmall);
		padding-left: spacing(small);
		border: 1px solid color(white);

		

		&:hover {
			&:before {
				width: 100%;
				height: 100%;
				left: 0;
				@include transition(all 0.35s);
			}

			.form-select__current__label, .form-select__reset__label {
				color: color(black);
			}

			.form-select__current__icon, .form-select__reset__icon {
				background: color(black);

				svg {
					polyline, path {
						stroke: color(white);
					}
				}
			}
		}

		&:before {
			content: '';
			width: 0;
			height: 0;
			left: spacing(small);
			bottom: 0;
			position: absolute;
			z-index: 1;
			background: color(white);
			@include transition(all 0.35s);
		}

		&__label {
			width: auto;
			min-width: 125px;
			display: inline-block;
			font-weight: 400;
			color: color(white);
			text-align: left;
			padding-right: spacing(xsmall);
			position: relative;
			z-index: 5;
		}

		&__icon {
			width: 24px;
			height: 24px;
			@extend %block-flex;
			justify-content: center;
			align-items: center;
			background: color(white);
			position: relative;
			z-index: 5;

			svg {
				width: 12px;
				height: 12px;

				polyline, path {
					fill: none;
					stroke: color(black);
					stroke-width: 3px;
				}
			}
		}

		.form-select__current__icon {
			svg {
				@include transform(rotate(90deg));
			}
		}
	}

	&__reset {
		&:hover {
			background: color(black);

			.form-select__reset__label {
				opacity: 1;
			}
		}
		&__label {
			opacity: 0;
		}
	}

	&__options {
		width: 100%;
		max-height: 180px;
		overflow-y: auto;
		display: none;
		position: absolute;
		bottom: 1px;
		left: 0;
		@include transform(translateY(100%));
		z-index: 999;
		border: 1px solid color(white);
		background: color(black);
		pointer-events: none;

		&__item {
			padding: spacing(xsmall) spacing(small);
			text-align: left;

			&:hover {
				background: color(white);
				color: color(black);
			}
		}
	}
}

// form {
// 	@extend %block;
// 	fieldset {
// 		@extend %block-flex;
// 		flex-wrap: wrap;
// 		margin-top: -1 * spacing(xsmall);
// 		margin-left: -1 * spacing(xsmall);
// 		padding: 0;
// 		border: none;
// 	}

// 	.clearfix {
// 		display: inline-block;
// 		flex: 0 2 100%;
// 		padding: spacing(xsmall);

// 		&.w50 {
// 			flex: 0 1 50%;
// 		}
// 	}

// 	label, .help-inline {
// 		display: none;
// 	}

// 	.input {
// 		@extend %block;


// 		input, textarea {
// 			@extend %block;
// 			padding: spacing(xsmall) spacing(small);
// 			border: 1px solid color(black);
// 			font-weight: 400;
// 			color: color(black);
// 			text-align: left;
// 		}

// 		input {
// 			height: 42px;
// 		}

// 		textarea {
// 			resize: none;
// 			min-width: 100%;
// 			max-width: 100%;
// 			min-height: 15rem;
// 		}

// 		.inputs-list {
// 			@extend %block-flex;
// 			justify-content: flex-start;
// 			align-items: center;
// 			gap: spacing(xsmall);

// 			li {
// 				list-style: none;

// 				&.active {
// 					label {
// 						background: color(black);
// 						color: color(white);
// 					}
// 				}

// 				input {
// 					display: none;
// 				}

// 				label {
// 					position: relative;
// 					padding: spacing(xsmall) spacing(small);
// 					display: block;
// 					color: color(black);
// 					font-weight: 400;
// 					border: 1px solid color(black);
// 					cursor: pointer;

// 					&:hover {
// 						color: color(white);

// 						&:before {
// 							width: 100%;
// 							height: 100%;
// 							left: 0;
// 							@include transition(all 0.35s);
// 						}
// 					}

// 					&:before {
// 						content: '';
// 						width: 0;
// 						height: 0;
// 						left: spacing(small);
// 						bottom: 0;
// 						position: absolute;
// 						z-index: 1;
// 						background: color(black);
// 						@include transition(all 0.35s);
// 					}

// 					span {
// 						position: relative;
// 						z-index: 5;
// 					}
// 				}
// 			}
// 		}
// 	}

// 	.actions {
// 		@extend %block;
// 		margin-top: spacing(medium);

// 		.form-navigation {
// 			@extend %block;

// 			ul {
// 				@extend %block-flex;
// 				gap: spacing(small);

// 				li {
// 					list-style: none;

// 					button {
// 						position: relative;
// 						background: none;
// 						width: auto;
// 						display: block;
// 						padding: spacing(xsmall) spacing(small);
// 						border: 1px solid color(black);
// 						font-weight: 400;
// 						color: color(black);
// 						text-align: left;
// 						cursor: pointer;
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// .form-contact {
// 	@extend %block;

// 	.neos-contentcollection {
// 		width: 100%;
// 		display: block;

// 		@include for-size(md) {
// 			width: 66.666%;
// 		}
// 	}

// 	.form-select {
// 		&__current, &__reset {
// 			border: 1px solid color(black);

// 			&:hover {
// 				.form-select__current__label, .form-select__reset__label {
// 					color: color(white);
// 				}

// 				.form-select__current__icon, .form-select__reset__icon {
// 					background: color(white);

// 					svg {
// 						polyline, path {
// 							stroke: color(black);
// 						}
// 					}
// 				}
// 			}

// 			&:before {
// 				background: color(black);
// 			}

// 			&__label {
// 				color: color(black);
// 			}

// 			&__icon {
// 				background: color(black);

// 				svg {
// 					polyline, path {
// 						stroke: color(white);
// 					}
// 				}
// 			}
// 		}

// 		&__reset {
// 			&:hover {
// 				background: color(white);
// 			}
// 		}

// 		&__options {
// 			border: 1px solid color(black);
// 			background: color(white);

// 			&__item {
// 				background: color(white);
// 				color: color(black);
// 				&:hover {
// 					background: color(black);
// 					color: color(white);
// 				}
// 			}
// 		}
// 	}
// }

form {
	@extend %block;
	fieldset {
		@extend %block-flex;
		flex-wrap: wrap;
		margin-top: -1 * spacing(xsmall);
		margin-left: -1 * spacing(xsmall);
		padding: 0;
		border: none;
	}

	.clearfix {
		display: inline-block;
		flex: 0 2 100%;
		padding: spacing(xsmall);

		&.w50 {
			flex: 0 1 50%;
		}

		&.error {
			input, textarea {
				border-color: red;
			}
		}
	}

	label {
		display: none;
	}

	.input {
		@extend %block;

		input, textarea {
			@extend %block;
			padding: spacing(xsmall) spacing(small);
			border: 1px solid color(white);
			font-weight: 400;
			color: color(white);
			text-align: left;
			background: color(black);

			&:focus-visible {
				background: color(white);
				color: color(black);
				outline: none;
			}
		}

		input {
			height: 42px;
		}

		textarea {
			resize: none;
			min-width: 100%;
			max-width: 100%;
			min-height: 15rem;
			padding-top: 0.75rem;
		}

		.help-inline {
			font-size: 0.8rem;
			color: red;
		}

		.inputs-list {
			@extend %block-flex;
			justify-content: flex-start;
			align-items: center;
			gap: spacing(xsmall);

			li {
				list-style: none;

				&.active {
					label {
						background: color(white);
						color: color(black);
					}
				}

				input {
					display: none;
				}

				label {
					position: relative;
					padding: spacing(xsmall) spacing(small);
					display: block;
					color: color(white);
					font-weight: 400;
					border: 1px solid color(white);
					cursor: pointer;

					&:hover {
						color: color(black);

						&:before {
							width: 100%;
							height: 100%;
							left: 0;
							@include transition(all 0.35s);
						}
					}

					&:before {
						content: '';
						width: 0;
						height: 0;
						left: spacing(small);
						bottom: 0;
						position: absolute;
						z-index: 1;
						background: color(white);
						@include transition(all 0.35s);
					}

					span {
						position: relative;
						z-index: 5;
					}
				}
			}
		}
	}

	.actions {
		@extend %block;
		margin-top: spacing(medium);

		.form-navigation {
			@extend %block;

			ul {
				@extend %block-flex;
				gap: spacing(small);

				li {
					list-style: none;

					button {
						position: relative;
						background: none;
						width: auto;
						display: block;
						padding: spacing(xsmall) spacing(small);
						border: 1px solid color(white);
						font-weight: 400;
						color: color(white);
						text-align: left;
						cursor: pointer;
					}
				}
			}
		}
	}
}

.form-contact {
	@extend %block;

	.neos-contentcollection {
		width: 100%;
		display: block;

		@include for-size(md) {
			width: 66.666%;
		}
	}

	.form-select {
		&__current, &__reset {
			border: 1px solid color(white);

			&:hover {
				.form-select__current__label, .form-select__reset__label {
					color: color(black);
				}

				.form-select__current__icon, .form-select__reset__icon {
					background: color(black);

					svg {
						polyline, path {
							stroke: color(white);
						}
					}
				}
			}

			&:before {
				background: color(white);
			}

			&__label {
				color: color(white);
			}

			&__icon {
				background: color(white);

				svg {
					polyline, path {
						stroke: color(black);
					}
				}
			}
		}

		&__reset {
			&:hover {
				background: color(black);
			}
		}

		&__options {
			border: 1px solid color(white);
			background: color(black);

			&__item {
				background: color(black);
				color: color(white);
				&:hover {
					background: color(white);
					color: color(black);
				}
			}
		}
	}
}