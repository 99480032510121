.logo {
	@extend %block-flex;
	justify-content: center;
	align-items: center;
	width: auto;
	height: 105px;
	position: absolute;
	left: 0;
	top: 0;
	background: color(white);
	padding-left: 5vw;
	padding-right: spacing(medium);

	@include for-size(md) {
		height: 125px;
	}

	@include for-size(xl) {
		padding-left: 12.5vw;
	}

	img {
		width: auto;
		height: 50px;
		display: block;

		@include for-size(md) {
			height: 65px;
		}
	}

	.on--scroll & {
		height: 80px;

		@include for-size(md) {
			height: 100px;
		}

		img {
			height: 40px;

			@include for-size(md) {
				height: 50px;
			}
		}
	}

	.oc-navigation & {
		height: auto;
		position: static;
		padding: 0;
		width: 100%;
		background: none;
		margin-bottom: spacing(medium);

		img {
			filter: invert(1);
			height: 50px !important;
		}
	}
}