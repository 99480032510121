.text,
.paragraph {
	width: 100%;
	&.shrinked {
		p {
			@include for-size(md) {
				width: 80%;
			}

			@include for-size(lg) {
				width: 66%;
			}
		}
	}

	p {
		width: 100%;
	}

	p + p {
		margin-top: spacing(small);
	}

	strong {
		font-weight: 700;
	}

	ul,
	ol {
		@extend %block;
		list-style-position: inside;

		li {
			//color: $color--font-base; ### is set with global color classes
			list-style: none;
			margin-bottom: 0.25rem;
			padding-left: spacing(medium);
			position: relative;

			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 8px;
				width: 8px;
				height: 8px;
				background: currentColor;
			}
		}
	}

	ul + *, ol + * {
		margin-top: spacing(small);
	}

	ul {
		list-style-type: disc;
	}

	ol {
		list-style-type: upper-roman;
	}

	figure.table {
		@extend %block;
		overflow-x: auto;
		overflow-y: hidden;
		margin-bottom: spacing(small);

		table {
			min-width: 100%;
			border: none;
			border-collapse: collapse;
			font-size: 0.85rem;
			table-layout: fixed;

			thead,
			tbody {
				tr {

					&:last-child {
						margin-bottom: 0;
					}

					th,
					td {
						text-align: left;
						font-weight: 400;
						line-height: 1.25;
						font-size: 0.95rem;
						padding-bottom: spacing(small);
					}

					th {
						font-weight: 500;
					}
				}
			}
		}
	}
}

.text,
.headline,
.paragraph {
	a {
		color: color(white);
		text-decoration: underline;
	}
}

a.link {
	color: color(white);
	text-decoration: underline;
}