h1,
h2,
h3,
h4,
h5,
h6 {
	@extend .outer-space--medium--top;
	@extend .outer-space--small--bottom;
	font-weight: typo(headline-weight);

	@if typo(headline-auto-hyphens) {
		@include hyphens(auto);
	}

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

h1 {
	font-size: nth(typo(h1-sizes), 1); // fallback font-size
	font-size: clamp(1.8rem, 5vw, 3.5rem);
	line-height: typo(h1-lheight);
}

h2 {
	font-size: nth(typo(h2-sizes), 1); // fallback font-size
	font-size: clamp(1.6rem, 4vw, 2rem);
	line-height: typo(h2-lheight);
}

h3 {
	font-size: nth(typo(h3-sizes), 1); // fallback font-size
	font-size: clamp(1.35rem, 3vw, 1.45rem);
	line-height: typo(h3-lheight);
}

h4 {
	font-size: nth(typo(h4-sizes), 1); // fallback font-size
	font-size: clamp(1.25rem, 2vw, 1.3rem);
	line-height: typo(h4-lheight);
}

p {
	@extend .outer-space--small--bottom;
	font-size: nth(typo(base-sizes), 1); // fallback font-size
	font-size: clamp(.95rem, 2vw, 1rem);
	line-height: typo(base-lheight);
	@if typo(base-auto-hyphens) == true {
		@include hyphens(auto);
	}

	&:last-child {
		margin-bottom: 0;
	}
}

small {
	@extend .outer-space--small--bottom;
	font-size: nth(typo(small-sizes), 1);
	font-size: clamp(0.9rem, 2vw, 0.9rem);
	line-height: typo(small-lheight);
	@if typo(base-auto-hyphens) {
		@include hyphens(auto);
	}

	&:last-child {
		margin-bottom: 0;
	}
}