@import url(typo(font-import));

html {
	font-size: typo(root-size);
	overflow-x: hidden;
}

body {
	font-family: typo(base-family);
	font-size: nth(typo(base-sizes), 1); // fallback font-size
	font-size: clamp(0.95rem, 2vw, 1rem);
	line-height: typo(base-lheight);
	font-weight: typo(base-weight);
	color: color(font-base);
	background: color(body);
	overflow-x: hidden;
}

.neos-contentcollection, .neos-inline-editable {
	@extend %block;
}