.cta-section {
	@extend %block-grid;
	grid-template-columns: 1fr;
	grid-gap: gridGap(medium);

	@include for-size(md) {
		grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
	}

	@include for-size(lg) {
		grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
	}

	&__col-1, &__col-2 {
		width: 100%;
		display: inline-block;

		.text {
			padding-right: spacing(small);
		}
	}
}