@mixin hyphens($values) {
	hyphens: $values;
	-moz-hyphens: $values;
	-webkit-hyphens: $values;
	-ms-hyphens: $values;
	// future hyphenation
	hyphenate-limit-chars: 5 3 3;
	-moz-hyphenate-limit-chars: 5 3 3;
	-webkit-hyphenate-limit-chars: 5 3 3;
	-ms-hyphenate-limit-chars: 5 3 3;
	hyphenate-limit-lines: 2;
	-moz-hyphenate-limit-lines: 2;
	-webkit-hyphenate-limit-lines: 2;
	-ms-hyphenate-limit-lines: 2;
	hyphenate-limit-last: always;
	-moz-hyphenate-limit-last: always;
	-webkit-hyphenate-limit-last: always;
	-ms-hyphenate-limit-last: always;
	hyphenate-limit-zone: 8%;
	-moz-hyphenate-limit-zone: 8%;
	-webkit-hyphenate-limit-zone: 8%;
	-ms-hyphenate-limit-zone: 8%;
}