.product-data {
	@extend %block-flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	gap: spacing(medium);

	@include for-size(lg) {
		flex-wrap: nowrap;
	}

	li {
		list-style: none;
		display: inline-block;
		width: auto;
		flex: 1 1 40%;

		@include for-size(sm) {
			flex: 1 1 20%;
		}

		@include for-size(md) {
			flex: 1 1 15%;
		}

		@include for-size(lg) {
			flex: 1 1 auto;
		}

		&.has--tooltip {
			position: relative;

			&:hover {
				.tooltip {
					opacity: 1;
					@include transform(translate(-50%, 100%));
					@include transition(all 0.2s ease-in-out);
				}
			}

			.tooltip {
				display: block;
			}
		}

		&.select-button {
			flex: 1 1 100%;

			@include for-size(sm) {
				flex: 1 1 40%;
			}

			@include for-size(md) {
				flex: 1 1 45%;
			}

			@include for-size(lg) {
				flex: 1 2 auto;
			}
		}

		&.download-button {
			align-self: center;
			flex: 1 1 100%;

			@include for-size(lg) {
				flex: 2 3 20%;
			}

			a {
				@extend %block-flex;
				justify-content: space-between;
				align-items: center;
				opacity: 0.25;
				position: relative;
				color: color(white);
				background: color(black);
				padding: spacing(small);
				border: 1px solid color(white);
				cursor: default;
				pointer-events: none;

				&.is--available {
					opacity: 1;
					cursor: pointer;
					pointer-events: all;

					&:before {
						content: '';
						width: 0;
						height: 0;
						position: absolute;
						bottom: 0;
						left: spacing(small);
						z-index: 1;
						background: color(white);
						@include transition(all 0.35s);
					}

					&:hover {
						&:before {
							width: 100%;
							height: 100%;
							left: 0;
							@include transition(all 0.35s);
						}

						.headline {
							color: color(black);
						}

						.download-icon {
							background: color(black);

							svg {
								path, line, polygon, circle, rect {
									stroke: color(white);
								}
							}
						}
					}
				}

				.headline {
					display: inline-block;
					position: relative;
					z-index: 5;
					padding-right: spacing(medium);

					.tags {
						text-transform: uppercase;
					}
				}

				.download-icon {
					@extend %block-flex;
					justify-content: center;
					align-items: center;
					width: auto;
					position: relative;
					z-index: 1;
					background: color(white);
					padding: spacing(xsmall);

					svg {
						display: inline-block;
						width: 24px;
						height: 24px;
						path, line, polygon, circle, rect {
							fill: none;
							stroke: color(black);
							stroke-width: 2px;
						}
					}
				}
			}
		}

		.tooltip {
			opacity: 0;
			position: absolute;
			bottom: -10px;
			left: 50%;
			z-index: 20;
			font-size: nth(typo(small-sizes), 1);
			font-size: clamp(0.9rem, 2vw, 0.9rem);
			line-height: typo(small-lheight);
			color: color(white);
			background: color(black);
			padding: spacing(xsmall) spacing(small);
			border: 1px solid color(white);
			pointer-events: none;
			@include transform(translate(-50%, 120%));
			@include transition(all 0.2s ease-in-out);

			&:after {
				content: '';
				width: 0;
				height: 0;
				position: absolute;
				top: -7px;
				left: 50%;
				border-right: 7px solid transparent;
				border-bottom: 7px solid color(white);
				border-left: 7px solid transparent;
				@include transform(translateX(-50%));
			}
		}

		figure.data-icon {
			@extend %block-flex;
			justify-content: center;
			align-items: center;

			img {
				@extend %block;
			}

			.svg {
				@extend %block-flex;
				justify-content: center;
				align-items: center;
				width: 36px;
				height: 36px;

				svg {
					width: 36px;
					height: 36px;

					path, line, polygon, circle, rect , polyline {
						fill: none;
						stroke: color(white);
						stroke-width: 5px;
					}
				}
			}
		}

		p, .form-select {
			@extend .outer-space--small--top;
			display: block;
			width: 100%;
			font-weight: 700;
			text-align: center;
		}

		p {
			@extend .outer-space--medium--top;
			line-height: 1.2;
		}
	}
}

.product-dataunits {
	display: none;
	visibility: hidden;
}

.product-datasheets {
	display: none;
	visibility: hidden;
}