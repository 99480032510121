.tiles-partner {
	.neos-contentcollection, &.output {
		@include for-size(sm) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@include for-size(lg) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
	}
	&__item {
		@extend %block-flex;
		justify-content: stretch;
		align-items: stretch;
		border: 1px solid color(white);
		flex-direction: column;

		@include for-size(md) {
			flex-direction: row;
		}

		&__logo {
			@extend %block-flex;
			justify-content: center;
			align-items: center;
			aspect-ratio: 1 / 1;

			@include for-size(md) {
				width: 50%;
				border-right: 1px solid color(white);
			}

			@include for-size(lg) {
				
			}

			img {
				width: 100%;
				object-fit: cover;
			}
		}

		&__content {
			min-width: 60%;
			display: inline-block;
			position: relative;
			padding: spacing(medium);
			padding-bottom: 106px;
		}

		.headline {
			margin-bottom: spacing(2xsmall);
		}
	}
}