.headline {
	@extend %block;

	h1, h2, h3, h4, h5, h6 {
		@extend %block;
	}

	&.has--option {
		@extend %block-flex;
		align-items: flex-start;
		justify-content: space-between;

		h3 {
			margin-bottom: 0;
		}

		button {
			width: auto;
			height: auto;
			background: color(black);
			border: 1px solid color(white);
			color: color(white);
			text-align: center;
			padding: spacing(xsmall) spacing(small);
			opacity: 0.25;
			pointer-events: none;
			cursor: default;
			white-space: nowrap;

			&.is--available {
				opacity: 1;
				pointer-events: all;
				cursor: pointer;
			}
		}
	}
}