.footer {
	background: color(white);
	padding-top: spacing(small);

	&__inner {
		@extend %block-flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}

	&__nav {
		width: 100%;
		padding: spacing(medium) 0;

		@include for-size(md) {
			width: auto;
		}

		ul {
			@extend %block-flex;
			flex-wrap: wrap;
			gap: spacing(small);

			li {
				list-style: none;
				display: block;
				width: 100%;

				@include for-size(sm) {
					display: inline-block;
					width: 45%;
				}

				@include for-size(md) {
					display: inline-block;
					width: auto;
				}

				a {
					position: relative;
					font-size: nth(typo(base-sizes), 1); // fallback font-size
					font-size: clamp(.95rem, 2vw, 1rem);
					line-height: typo(small-lheight);
					color: color(black);
					padding: spacing(xsmall) spacing(small);

					@include for-size(md) {
						font-size: nth(typo(small-sizes), 1); // fallback font-size
						font-size: clamp(0.9rem, 2vw, 0.9rem);
					}

					&:hover {
						color: color(white);

						&:before {
							width: 100%;
							height: 100%;
							left: 0;
							@include transition(all 0.35s);
						}
					}

					&:before {
						content: '';
						width: 0;
						height: 0;
						position: absolute;
						bottom: 0;
						left: spacing(small);
						z-index: 1;
						background: color(black);
						@include transition(all 0.35s);
					}

					span {
						position: relative;
						z-index: 5;
					}
				}
			}
		}
	}

	&__copyright {
		display: block;
		width: 100%;
		background: color(black);
		padding: spacing(small);
		text-align: center;

		@include for-size(md) {
			display: inline-block;
			width: auto;
			padding: spacing(medium);
		}
	}
}