.distributors-list {
	@extend %block;

	&__filter {
		@extend %block-flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: spacing(medium);
		flex-wrap: wrap;
		gap: spacing(small);

		.form-select {
			width: 100%;
			display: block;

			@include for-size(sm) {
				width: auto;
				display: inline-block;
			}
		}
	}

	.neos-contentcollection {
		@extend %block-grid;
		justify-content: flex-start;
		align-items: stretch;
		grid-gap: gridGap(medium);
		grid-template-columns: 1fr;

		@include for-size(sm) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@include for-size(md) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
	}

	&__item {
		width: 100%;
		display: inline-block;
		position: relative;
		padding-bottom: 106px; // 2x spacing(medium)[2x 36px] + icon-height off 36px = 114px

		&.hide--country, &.hide--zip {
			display: none;
		}

		.headline {
			margin-bottom: spacing(2xsmall);
		}
	}
}