[class*=boxed--] {
	@extend %block-flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.boxed--small {
	padding-left: 10vw;
	padding-right: 10vw;

	@include for-size(md) {
		padding-left: 25vw;
		padding-right: 25vw;
	}

	@include for-size(lg) {
		padding-left: 30vw;
		padding-right: 30vw;
	}

	&>* {
		max-width: boxedMax(small);
	}

	&--left {
		padding-left: 15vw;
		padding-right: 5vw;

		@include for-size(md) {
			padding-left: 5vw;
			padding-right: 45vw;
		}

		@include for-size(lg) {
			padding-left: 5vw;
			padding-right: 55vw;
		}

		&>* {
			max-width: boxedMax(small);
		}
	}

	&--right {
		padding-left: 5vw;
		padding-right: 15vw;

		@include for-size(md) {
			padding-left: 45vw;
			padding-right: 5vw;
		}

		@include for-size(lg) {
			padding-left: 55vw;
			padding-right: 5vw;
		}

		&>* {
			max-width: boxedMax(small);
		}
	}
}

.boxed--medium {
	padding-left: 5vw;
	padding-right: 5vw;

	@include for-size(md) {
		padding-left: 17.5vw;
		padding-right: 17.5vw;
	}

	@include for-size(lg) {
		padding-left: 25vw;
		padding-right: 25vw;
	}

	&>* {
		max-width: boxedMax(medium);
	}

	&--left {
		padding-left: 5vw;
		padding-right: 5vw;

		@include for-size(md) {
			padding-left: 5vw;
			padding-right: 30vw;
		}

		@include for-size(lg) {
			padding-left: 5vw;
			padding-right: 45vw;
		}

		&>* {
			max-width: boxedMax(medium);
		}
	}

	&--right {
		padding-left: 5vw;
		padding-right: 5vw;

		@include for-size(md) {
			padding-left: 30vw;
			padding-right: 5vw;
		}

		@include for-size(lg) {
			padding-left: 45vw;
			padding-right: 5vw;
		}

		&>* {
			max-width: boxedMax(medium);
		}
	}
}

.boxed--large {
	padding-left: 5vw;
	padding-right: 5vw;

	@include for-size(xl) {
		padding-left: 12.5vw;
		padding-right: 12.5vw;
	}

	&>* {
		max-width: boxedMax(large);
	}

	&--left {
		padding-left: 5vw;
		padding-right: 5vw;

		@include for-size(xl) {
			padding-left: 5vw;
			padding-right: 20vw;
		}

		&>* {
			max-width: boxedMax(large);
		}
	}

	&--right {
		padding-left: 5vw;
		padding-right: 5vw;

		@include for-size(xl) {
			padding-left: 20vw;
			padding-right: 5vw;
		}

		&>* {
			max-width: boxedMax(large);
		}
	}
}

.boxed--full {
		padding-left: 2.5vw;
		padding-right: 2.5vw;

	@include for-size(lg) {
		padding-left: 5vw;
		padding-right: 5vw;
	}
}