figure.image {
	@extend %block;
	position: relative;

	&.fill {

		img,
		a {
			@extend %block;
		}
	}

	&.left {
		@extend %block-flex;
		justify-content: flex-start;
	}

	&.center {
		@extend %block-flex;
		justify-content: center;
	}

	&.right {
		@extend %block-flex;
		justify-content: flex-end;
	}

	a {
		display: block;
		width: auto;
		height: auto;

		&.img-lightbox {
			@extend %block;
			position: relative;

			&:hover {
				cursor: pointer !important;

				.open-lightbox {
					opacity: 1;
					@include transition(all 0.35s ease);
				}
			}

			.open-lightbox {
				background: rgba(0, 0, 0, 0.25);
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				opacity: 0;
				@include transition(all 0.35s ease);

				.open-lightbox--icon {
					height: 64px;
					width: 64px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: rgba(255, 255, 255, 0.95);
					padding: 1rem;
					@include radius(small);

					i {
						color: color(black);
						font-size: 1.75rem;
					}
				}
			}
		}
	}

	img {
		display: block;
		width: auto;
		height: auto;
	}

	figcaption {
		position: absolute;
		left: 0.35rem;
		bottom: 0.35rem;
		width: calc(100% - 0.7rem);
		height: auto;
		display: block;
		background: rgba(0, 0, 0, 0.5);
		text-align: center;
		font-weight: 600;
		font-size: 0.85rem;
		color: #fff !important;
		padding: spacing(small)*0.66;
		@include backdrop-filter(blur(10px));
		@include radius(small);
		overflow: hidden;
	}
}