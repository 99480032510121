.card {
	width: 100%;
	display: block;
	padding: spacing(medium);

	&--light {
		background: color(white);
		border: 1px solid color(black);
		color: color(black);
	}

	&--dark {
		background: color(black);
		border: 1px solid color(white);
		color: color(white);
	}

	&--download--light {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;

		&:hover {
			&:before {
				width: 100%;
				height: 100%;
				left: 0;
				@include transition(all 0.35s);
			}

			.headline {
				color: color(white);
				@include transition(all 0.35s);
			}

			.download-icon {
				background: color(white);
				@include transition(all 0.35s 0.15s);

				svg {
					path, line, polygon, circle, rect {
						stroke: color(black);
						@include transition(all 0.35s 0.15s);
					}
				}
			}
		}

		&.link--disabled {
			pointer-events: none;
			cursor: default;
		}

		&:before {
			content: '';
			width: 0;
			height: 0;
			left: spacing(medium);
			bottom: 0;
			position: absolute;
			z-index: 0;
			background: color(black);
			@include transition(all 0.15s 0.1s);
		}

		.headline {
			position: relative;
			z-index: 5;
			display: inline-block;
			padding-right: spacing(medium);
			@include transition(all 0.35s);

			.tags {
				text-transform: uppercase;
			}
		}

		.download-icon {
			@extend %block-flex;
			width: auto;
			justify-content: center;
			align-items: center;
			position: relative;
			z-index: 5;
			padding: spacing(xsmall);
			background: color(black);
			@include transition(all 0.35s);

			svg {
				display: inline-block;
				width: 24px;
				height: 24px;
				path, line, polygon {
					fill:none;
					stroke:color(white);
					stroke-width: 2px;
					@include transition(all 0.35s);
				}
			}
		}
	}

	&--download--dark {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;

		&:hover {
			&:before {
				width: 100%;
				height: 100%;
				left: 0;
				@include transition(all 0.35s);
			}

			.headline {
				color: color(black);
				@include transition(all 0.35s);
			}

			.download-icon {
				background: color(black);
				@include transition(all 0.35s 0.15s);

				svg {
					path, line, polygon, circle, rect {
						stroke: color(white);
						@include transition(all 0.35s 0.15s);
					}
				}
			}
		}

		&.link--disabled {
			pointer-events: none;
			cursor: default;
		}

		&:before {
			content: '';
			width: 0;
			height: 0;
			left: spacing(medium);
			bottom: 0;
			position: absolute;
			z-index: 0;
			background: color(white);
			@include transition(all 0.15s 0.1s);
		}

		.headline {
			position: relative;
			z-index: 5;
			display: inline-block;
			padding-right: spacing(medium);
			@include transition(all 0.35s);

			.tags {
				text-transform: uppercase;
			}
		}

		.download-icon {
			@extend %block-flex;
			width: auto;
			justify-content: center;
			align-items: center;
			position: relative;
			z-index: 5;
			padding: spacing(xsmall);
			background: color(white);
			@include transition(all 0.35s);

			svg {
				display: inline-block;
				width: 24px;
				height: 24px;
				path, line, polygon {
					fill:none;
					stroke:color(black);
					stroke-width: 2px;
					@include transition(all 0.35s);
				}
			}
		}
	}
}