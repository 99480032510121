.hero {
	@extend %block;
	top: 0;
	height: clamp(450px, 80vh, 1000px);
	position: relative;
	overflow: hidden;
	background: color(black);
	
	&::after {
		content: "";
		position: absolute;
		z-index: 5;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 50%;
		@include gradient(linear, to top, rgba(0, 0, 0, 0.75), transparent);
	}

	figure.hero-image {
		position: absolute;
		inset: 0;
		z-index: 1;

		img {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.hero-title {
		position: absolute;
		z-index: 10;
		left: 0;
		bottom: spacing(xlarge);
		color: color(white);

		&__inner {
			@extend %block;
		}

		h1 {
			width: 100%;

			@include for-size(md) {
				width: 80%;
			}

			@include for-size(lg) {
				width: 66%;
			}
		}
	}
 }