nav.navigation {
	@extend %block-flex;
	justify-content: flex-end;
	align-items: center;
	height: 80px;

	@include for-size(md) {
		height: 100px;
	}
}

.navigation--default {
	display: none;

	@include for-size(md) {
		display: flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		width: 100%;
		justify-content: flex-end;
		align-items: stretch;
		flex-wrap: wrap;
	}

	> li {
		list-style: none;
		display: inline-block;
		position: relative;
		padding: spacing(small) 0;


		&:hover {
			> ul {
				opacity: 1;
				pointer-events: visible;
				@include transition(all 0.35s);
			}

			> a {
				&:before {
					width: 100%;
					height: 100%;
					left: 0;
					@include transition(all 0.35s);
				}
				color: color(black);
			}
		}

		> a {
			display: block;
			padding: spacing(xsmall) spacing(small);
			border: 1px solid color(white);
			color: color(white);
			position: relative;
			overflow: hidden;
			font-size: 0.9rem;

			&:before {
				content: '';
				width: 0;
				height: 0;
				left: spacing(small);
				bottom: 0;
				position: absolute;
				z-index: 1;
				background: color(white);
				@include transition(all 0.35s);
			}

			span {
				position: relative;
				z-index: 5;
			}
		}

		ul {
			background: color(white);
			width: auto;
			display: block;
			position: absolute;
			bottom: spacing(xsmall);
			left: 0;
			border: 1px solid color(white);
			@include transform(translateY(100%));
			opacity: 0;
			pointer-events: none;
			@include transition(all 0.35s);

			li {
				width: 100%;
				display: block;

				a {
					width: 100%;
					display: block;
					padding: spacing(xsmall) spacing(small);
					color: color(black);
					font-size: 0.9rem;
					@include transition(all 0.35s);

					&:hover {
						background: color(black);
						color: color(white);
					}
				}
			}
		}
	}

	> li + li {
		margin-left: spacing(small);
	}
}

.oc-navigation__toggle {
	@extend %block-flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: spacing(small);
	right: 5vw;
	height: 48px;
	width: 48px;
	border: 1px solid color(white);
	cursor: pointer;

	&.oc-navigation__toggle__open {
		position: relative;
		top: 0;
		right: 0;
		@include for-size(md) {
			display: none;
		}
	}

	&:hover {
		&:before {
			width: 100%;
			height: 100%;
			left: 0;
			@include transition(all 0.35s);
		}

		svg {
			path, line, polygon, circle, rect, polyline {
				stroke: color(black);
			}
		}
	}

	&:before {
		content: '';
		width: 0;
		height: 0;
		left: spacing(small);
		bottom: 0;
		position: absolute;
		z-index: 1;
		background: color(white);
		@include transition(all 0.35s);
	}

	svg {
		position: relative;
		z-index: 10;
		display: block;
		width: 24px;
		height: 24px;
		@include transition(all 0.25s 0.15s);

		path, line, polygon, circle, rect, polyline {
			fill: none;
			stroke: color(white);
			stroke-width: 3px;
			@include transition(all 0.25s);
		}
	}
}

.oc-navigation {
	@extend %block-flex;
	justify-content: center;
	align-items: flex-start;
	align-content: flex-start;
	flex-wrap: wrap;
	padding: 5vw;
	padding-top: spacing(3xlarge);
	background: color(black);
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	max-height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 99999;
	opacity: 0;
	@include transform(translateY(-100%));
	pointer-events: none;
	@include transition(opacity 0.25s ease-in-out);

	&.show {
		opacity: 1;
		@include transform(translateY(0));
		pointer-events: auto;
		@include transition(opacity 0.25s ease-in-out);
	}
}

.navigation--mobile {
	@extend %block;
	max-width: 500px;

	> li {
		list-style: none;
		display: block;
		position: relative;

		&:hover {
			>a {
				&:before {
					width: 100%;
					height: 100%;
					left: 0;
				}
				color: color(black);
			}
			>.more-button {
				svg {
					path, line, polygon, circle, rect, polyline {
						stroke: color(black);
					}
				}
			}
		}

		&.show {
			>.more-button {
				svg {
					@include transform(rotate(-90deg));
					@include transition(all 0.35s);
				}
			}
			>ul {
				border: 1px solid color(white);
				height: auto;
				opacity: 1;
				pointer-events: auto;
			}
		}

		> a {
			display: block;
			padding: spacing(xsmall) spacing(small);
			border: 1px solid color(white);
			color: color(white);
			position: relative;
			overflow: hidden;
			font-size: 1.1rem;

			&:before {
				content: '';
				width: 0;
				height: 0;
				left: spacing(small);
				bottom: 0;
				position: absolute;
				z-index: 1;
				background: color(white);
			}

			span {
				position: relative;
				z-index: 5;
			}
		}

		.more-button {
			@extend %block-flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 5;
			height: 42px;
			width: 42px;
			cursor: pointer;

			svg {
				display: inline-block;
				width: 18px;
				height: 18px;
				@include transform(rotate(90deg));
				@include transition(all 0.25s 0.15s);

				path, line, polygon, circle, rect, polyline {
					fill: none;
					stroke: color(white);
					stroke-width: 3px;
					@include transition(all 0.25s);
				}
			}
		}

		ul {
			@extend %block;
			background: color(white);
			border: 1px solid color(white);
			height: 0;
			opacity: 0;
			pointer-events: none;

			li {
				width: 100%;
				display: block;

				a {
					width: 100%;
					display: block;
					padding: spacing(xsmall) spacing(small);
					color: color(black);
					font-size: 1rem;

					&:hover {
						background: color(black);
						color: color(white);
					}
				}
			}
		}
	}

	> li + li {
		margin-top: spacing(small);
	}
}