.button-group {
	@extend %block-flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	gap: spacing(small);
}

.button {
	$self: &;
	display: inline-block;
	padding: spacing(2xsmall) spacing(xsmall);
	border: 2px solid color(black);
	position: relative;

	&:hover {
		&:before {
			width: 100%;
			height: 100%;
			left: 0;
			@include transition(all 0.35s);
		}

		#{$self}__label {
			color: color(white);
			@include transition(all 0.35s);
		}
	}

	&:before {
		content: '';
		width: 0;
		height: 0;
		left: spacing(2xsmall);
		bottom: 0;
		position: absolute;
		z-index: 0;
		background: color(black);
		@include transition(all 0.15s 0.1s);
	}

	&.link--disabled {
		pointer-events: none;
		cursor: default;
	}

	&__label {
		display: inline-block;
		font-weight: 400;
		color: color(black);
		position: relative;
		z-index: 5;
		@include transition(all 0.35s);
	}
}