.tiles-slider {
	@extend %block;
	position: relative;

	.tns-outer {
		margin-top: 0;
		cursor: grab;
	}

	//.tns-ovh {
	//	overflow: visible;
	//}

	

	&__item {
		width: 100%;
		display: inline-block;
		opacity: 0.2;
		@include transition(opacity 0.35s);

		&.tns-slide-active {
			opacity: 1;
		}

		&__inner {
			width: 100%;
			display: block;
			border: 1px solid color(white);
			background: color(black);
			padding: spacing(medium);

			.headline {

			}
		}
	}

	.tns-nav {
		@extend %block-flex;
		gap: spacing(small);
		margin-top: spacing(small);

		button {
			width: 12px;
			height: 12px;
			background: transparent;
			border: 1px solid color(white);

			&.tns-nav-active {
				background: color(white);
			}
		}
	}

	.slide-prev, .slide-next {
		@extend %block-flex;
		width: auto;
		justify-content: center;
		align-items: center;
		z-index: 1;
		padding: spacing(xsmall);
		background: color(white);
		position: absolute;
		top: -20px;
		cursor: pointer;
		@include transform(scale(1));
		@include transition(transform 0.35s);

		&:hover {
			@include transform(scale(1.2));
		}

		&.hidden {
			visibility: hidden;
		}

		svg {
			display: inline-block;
			width: 24px;
			height: 24px;
			path, polyline {
				fill:none;
				stroke:color(black);
				stroke-width:3px;
			}
		}
	}

	.slide-prev {
		left: 0;
	}

	.slide-next {
		right: 0;
	}
}