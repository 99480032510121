.tiles {
	@extend %block;

	.neos-contentcollection, &.output {
		@extend %block-grid;
		grid-template-columns: 1fr;
		grid-gap: gridGap(medium);
		justify-content: center;
		align-items: stretch;

		@include for-size(md) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@include for-size(lg) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
	}

	&__item {
		@extend %block-flex;
		align-items: stretch;
		align-content: stretch;
		background: color(black);
		border: 1px solid color(white);

		&:hover {
			.tiles__item__header {
				.image img {
					@include for-size(md) {
						@include transform(scale(1.1));
						@include transition(transform 0.35s 0.1s);
					}
				}

				.headline {
					color: color(black);
					background: color(white);

					@include for-size(md) {
						@include transition(all 0.15s 0.1s);
					}
				}
			}

			.tiles__item__content {
				color: color(black);

				@include for-size(md) {
					@include transition(color 0.15s);
				}

				&:before {
					width: 100%;
					height: 100%;
					left: 0;

					@include for-size(md) {
						@include transition(all 0.15s);
					}
				}


				.more-icon {
					background: color(black);

					svg {
						path {
							stroke:color(white);
						}
					}
				}
			}
		}

		a {
			@extend %block-flex;
			flex-direction: column;
			align-items: stretch;
			align-content: stretch;
			position: relative;
		}

		&__header {
			@extend %block;
			position: relative;
			border-bottom: 1px solid color(white);
			aspect-ratio: 16 / 9;

			.image {
				position: absolute;
				overflow: hidden;
				inset: 0;

				img {
					width: 100%;
					height: 100%;
					position: absolute;
					z-index: 0;
					object-fit: cover;
					inset: 0;
					@include transform(scale(1));

					@include for-size(md) {
						@include transition(transform 0.25s);
					}
				}
			}

			.headline {
				display: inline-block;
				width: auto;
				max-width: 80%;
				position: absolute;
				bottom: -1px;
				left: spacing(small);
				z-index: 5;
				color: color(white);
				background: color(black);
				padding: spacing(small) spacing(small) 0 spacing(small);
				border: 1px solid color(white);
				border-bottom: none;

				@include for-size(md) {
					padding: spacing(small) spacing(medium) 0 spacing(medium);
					@include transition(all 0.15s);
				}

				h4 {
					display: inline-block;
					width: auto;
					line-height: 1;
				}
			}
		}

		&__content {
			@extend %block-flex;
			flex-grow: 1;
			justify-content: center;
			align-items: center;
			position: relative;
			color: color(white);
			padding: spacing(medium);

			@include for-size(md) {
				padding: spacing(large);
				@include transition(color 0.15s 0.1s);
			}

			&:before {
				content: '';
				width: 0;
				height: 0;
				position: absolute;
				bottom: 0;
				left: spacing(medium);
				z-index: 0;
				background: color(white);

				@include for-size(md) {
					@include transition(all 0.15s 0.1s);
				}
			}

			.text {
				display: inline-block;
				position: relative;
				z-index: 1;
				padding-right: spacing(small);

				p, span {
					font-size: nth(typo(base-sizes), 1);
					font-size: clamp(.95rem, 2vw, 1rem);
					line-height: typo(small-lheight);
				}
			}

			.more-icon {
				@extend %block-flex;
				justify-content: center;
				align-items: center;
				width: auto;
				position: relative;
				z-index: 1;
				background: color(white);
				padding: spacing(xsmall);

				svg {
					display: inline-block;
					width: 24px;
					height: 24px;
					path {
						fill:none;
						stroke:color(black);
						stroke-width:3px;
					}
				}
			}
		}
	}

	&__item--cta {
		display: inline-block;
		width: 100%;
		position: relative;
		background: color(black);
		padding: spacing(medium);
		padding-bottom: 106px; // 2x spacing(medium)[2x 36px] + icon-height off 36px = 114px
		border: 1px solid color(white);

		.headline {
			margin-bottom: spacing(2xsmall);
		}
	}
}