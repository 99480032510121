ul.contact-options {
	@extend %block-flex;
	flex-wrap: wrap;
	position: absolute;
	left: 0;
	bottom: 0;
	padding: spacing(medium);

	li {
		list-style: none;
		display: inline-block;

		& + li {
			margin-left: spacing(small);
		}

		a {
			@extend %block-flex;
			justify-content: center;
			align-items: center;
			position: relative;
			width: 42px;
			height: 42px;
			background: color(black);
			border: 1px solid color(white);
			@include transition(stroke 0.2s ease-in-out);

			&:before {
				content: '';
				width: 0;
				height: 0;
				left: spacing(2xsmall);
				bottom: 0;
				position: absolute;
				z-index: 1;
				background: color(white);
				@include transition(all 0.35s);
			}

			&:hover {
				&:before {
					width: 100%;
					height: 100%;
					left: 0;
					@include transition(all 0.35s);
				}

				svg {
					path, line, polygon, circle, rect , polyline, ellipse {
						stroke: color(black);
					}
				}
			}

			svg {
				width: 24px;
				height: 24px;
				position: relative;
				z-index: 5;

				path, line, polygon, circle, rect , polyline, ellipse {
					fill: none;
					stroke: color(white);
					stroke-width: 2px;
					stroke-miterlimit: 8;
					stroke-linejoin: round;
				}
			}
		}
	}
}