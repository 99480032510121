.icon-list {
	@extend %block;

	.neos-contentcollection {
		@extend %block-grid;
		grid-gap: gridGap(medium);
		justify-content: center;
		align-items: center;

		@include for-size(sm) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@include for-size(md) {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}
	}

	&__item {
		display: inline-block;
		width: 100%;
		position: relative;

		img {
			@extend %block;
		}

		.svg {
			@extend %block-flex;
			justify-content: center;
			align-items: center;
			

			svg {
				width: 64px;
				max-width: 64px;
				height: 64px;
				max-height: 64px;
			}
		}

		figcaption {
			@extend .outer-space--small--top;
			font-size: nth(typo(small-sizes), 1);
			font-size: clamp(0.9rem, 2vw, 0.9rem);
			line-height: typo(small-lheight);
			text-align: center;
			@if typo(base-auto-hyphens) {
				@include hyphens(auto);
			}
		}

		a {
			position: absolute;
			z-index: 1;
			inset: 0;
		}
	}
}